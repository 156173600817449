  .header-bg {
      /* background: url('../../../public/images/img-home.jpg') center center/cover no-repeat; */
      height: 850px;
      width: 100%;
      display: flex;
      flex-direction: column;
      object-fit: container;
      position: relative;
  }

  .showcase-container
  {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 850px;
    position: absolute;
  }

  .showcase-group {
    margin-block-start: 15rem;
    text-align: center;
    position: absolute;
    padding: 1rem;
    padding-top: 0rem;
  }

  .showcase-header {
    color: #fff;
    font-size: 2.5rem;
    font-family: 'Rock Salt', cursive;
    font-weight: 100;
  }

  .showcase-tag
  {
    color: #fff;
    margin-bottom: auto;
  }

  .showcase-photo {
    position: absolute;
    border-radius: 50%;
    height: 20rem;
    margin-inline: auto;
    margin-top: -250px;
    /* image-rendering: optimizeSpeed; */
  }

  .wave {
    position: absolute;
    top: 10rem;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    margin-bottom: -1px;
    transform: rotate(180deg);
  }
  
  .wave svg {
    position: relative;
    display: block;
    width: calc(146% + 1.3px);
    height: 200px;
  }
  
  .wave .shape-fill {
    fill: #fff;
  }
  
  @media screen and (max-width: 768px) {
    .header-bg {
      height: 520px;
    }

    .showcase-header {
      font-size: 1.5rem;
    }

    .showcase-group {
      margin-block-start: -7rem;
    }

    .showcase-tag {
      font-size: larger;
    }

    .showcase-photo {
      margin-top: -500px;
      height: 15rem;
    }

    .wave svg {
      height: 70px;
    }

    .wave {
      margin-bottom: -1px;
    }
  }