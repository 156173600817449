* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  .home,
  .about,
  .products {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .products {
    background-image: url('../public/images/img-15.jpg');
    background-position: center;
    background-size: cover;    
    background-repeat: no-repeat;
    color: #fff;
    image-rendering: optimizeSpeed;
  }

.container {
  width: 100%;
  overflow: hidden;
}

.hint-text
{
  margin-top: 20px;
  text-align: center;
}

