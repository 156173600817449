.contact-form-container
{
  padding: 5%;
  padding-top: 15%;
  width: 100%;
}

.contact-group 
{
  margin-top: 3%;
  text-align: center;
}

.button-group 
{
  margin-top: 3%;
  text-align: center;
}

.button-group > * + * {
  margin-top: 3%;
}

.contact-input
{
  margin-top: 10px;
  height: 35px;
  width: 100%;
  border-radius: 1rem;
  text-indent: 3%;
}

.login-input
{
  height: 35px;
  width: 100%;
  border-radius: 1rem;
  text-indent: 3%;
}

.contact-form
{
  padding-left: 10%;
  padding-right: 10%;
}

.contact-form-background
{
  position: relative;
  margin: auto;
  justify-content: center;
  background-color: rgba(138, 138, 138, 0.7);
  border-radius: 1rem;
  padding: 20px;
  max-width: 763.5px;
  min-width: 337.5px;
}

.contact-label
{
  text-align: center;
  padding-bottom: 3%;
}

.button-space
{
  margin-left: 20px;
}

.contact-button {
  min-width: 10em;
  min-height: 2.5em;
  color: white;
  border-style: none;
  border-radius: 100vw;
}

.contact-button:hover {
  box-shadow: inset 0px 0px 10px 2.5px rgba(0, 0, 0, .5);
}

.back-color {
  background-color: rgb(195, 46, 46);
}

.enter-color {
  background-color: steelblue;
}

.text-center {
  text-align: center;
}

.no-style {
  color: inherit;
  text-decoration: none;
}

.contact-error-container {
  width: 98%;
  text-align: center;
  border: 2px solid rgb(232, 65, 65);
  background-color: rgba(232, 65, 65, 0.667);
  border-radius: .6em;
  margin-inline: auto;
  padding-block: .5em;
}

.contact-error-container > * + * {
  padding-top: 1em;
}

.contact-error-message {
  font-size: small;
}

@media screen and (min-width: 90em) {
  .contact-form-container{
    padding-top: 15%;
  }
  
  .button-group > * + * {
    margin-top: 0;
    margin-left: 2%;
  }

  .contact-error-container {
    width: 70%;
    text-align: center;
    border: 2px solid rgb(232, 65, 65);
    background-color: rgba(232, 65, 65, 0.667);
    border-radius: .6em;
    margin-inline: auto;
    padding-block: .5em;
  }
  
  .error-message {
    font-size: medium;
  }
}

@media screen and (max-width: 50em) {
  .contact-form-container{
    padding-top: 10em;
    
  }

  .button-group > * + * {
    margin-left: 2%;
  }
}