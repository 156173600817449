.photo-panel-container{
  padding: 10%;
}

.photo-split {
  margin-block-start: 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-container {
  display: flex;
  flex-direction: column;
  min-width: 20em;
}

.panel-photo {
  display: block;
  position: relative;
  margin-block: auto;
  max-height: 9vh;
  max-width: 100%; 
  height: auto;
  width: auto; 
}

.text-title {
  margin-top: 2em;
  text-align: start;
  font-size: x-large;
  font-family: 'Rock Salt', cursive;
}

.text-tag {
  font-size: larger;
}

.text-content {
  margin-top: 2em;
}

@media screen and (min-width: 90em) {
  .photo-panel-container {
    padding: 0;
    padding-top: 1%;
    padding-bottom: 5%;
    padding-inline: 30%;
  }

  .photo-split {
    margin-block-start: 2rem;
    padding-block: 0;
    flex-direction: row;
  }

  .photo-split > * {
    flex-basis: 100%;
  }

  .photo-split > * + * {
    margin-left: 5em;
    margin-top: 0;
  }

  .info-title {
    margin-top: 0;
    font-size: xx-large;
  }

  .text-title {
    margin-block-start: 0;
  }

  .panel-photo {
    margin-block: auto;
    max-height: 170px;
    max-width: 480px;
  }
}

@media screen and (max-width: 110em) {
  .info-title{
    font-size: x-large;
  }

  .photo-split {
    margin-left: -15%;
  }
}

@media screen and (max-width: 90em) {
  .photo-panel-container{
    padding-top: 5%;
  }

  .photo-split {
    margin-left: 0%;
  }
}