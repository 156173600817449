.login-form-container
{
  justify-content: center;
  padding-block: 5%;
  padding-inline: auto;
  width: 100%;
}

.login-group 
{
  margin-top: 3%;
  text-align: center;
}

.login-button-group 
{
  margin-top: 3%;
  text-align: center;
}

.login-button-group > * + * {
  margin-left: 20px;
}

.register-input
{
  margin-top: 10px;
  height: 35px;
  width: 100%;
  border-radius: 1rem;
  text-indent: 3%;
}

.login-input
{
  height: 35px;
  width: 100%;
  border-radius: 1rem;
  text-indent: 3%;
}

.login-form
{
  padding-left: 10%;
  padding-right: 10%;
}

.login-form-background
{
    width: 30%;
    margin-inline: auto;
  min-width: 365px;
  justify-content: center;
  background-color: lightgrey;
  border-radius: 1rem;
  padding: 20px;
}

.login-label
{
  text-align: center;
  padding-bottom: 3%;
}

.button-space
{
  margin-left: 20px;
}

.auth-error-container {
  width: 70%;
  text-align: center;
  border: 2px solid rgb(232, 65, 65);
  background-color: rgba(232, 65, 65, 0.667);
  border-radius: .6em;
  margin-inline: auto;
  margin-top: 12px;
  padding-block: .5em;
}

.auth-error-container > * + * {
  padding-top: 1em;
}

.auth-error-message {
  font-size: small;
}

@media screen and (min-width: 90em) {
  .login-button-group > * + * {
    margin-left: 20px;
  }

  .auth-error-message {
    font-size: medium;
  }
}

@media screen and (max-width: 50em) {
  .login-button-group > * + * {
    margin-top: 15px;
    margin-left: 0px;
  }
}