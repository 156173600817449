.panel-container {
    background: url("../../../../public/images/img-12.jpg") center center/cover no-repeat;
    height: 98vh;
    width: auto;
    display: block;
    justify-content: center;
    align-items: center;
    object-fit: container;
    min-height: 550px;
    image-rendering: optimizeSpeed;
}

.edit-panel {
    background: url("../../../../public/images/img-11.jpg") center center/cover no-repeat;
}

.contact-list {
    background: url("../../../../public/images/img-13.jpg") center center/cover no-repeat;
    padding-top: 7%;
}


@media screen and (min-width: 90em) {
    .panel-container {
        height: 95vh;
    }    
}

/* @media screen and (min-width: 50em) {
    .panel-container {
        height: 81em;
    }    
} */


/* @media screen and (max-width: 960px) {
    .panel-container {
        height: 91.2vh;
    }
} */

@media screen and (min-height: 1000px) {
    .panel-container {
        min-height: 90vh;
    }

    .contact-list {
        padding-top: 2%;
    }
}

@media screen and (max-height: 1920px) {
    .contact-list {
        padding-top: 0%;
    }
}


@media screen and (min-height: 1000px) and (min-width: 90em) {
    .panel-container {
        padding-top: 2%;
        min-height: 1000px;
    }

    .contact-list {
        padding-top: 2%;
    }
}