.scrollodex-container
{
    padding: 5% 20% 5% 20%;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.scrollodex-background
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    width: 100%;
    text-align: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .8);
    border-radius: 1rem;
    padding: 20px;
}

.scrollodex {
    background-color: whitesmoke;
    margin-top: 30px;
    height: 50vh;
    width: 70%;
    overflow-y: auto;
    border-radius: .5rem;
    margin-bottom: 1%;
    padding: 1%;
    padding-top: .8%;
    white-space: nowrap;
}

.scrollodex::-webkit-scrollbar {
    border-top-left-radius: .5rem;
}

.scrollodex::-webkit-scrollbar-track {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
    background-color: rgb(150, 150, 150);
}

.scrollodex::-webkit-scrollbar-thumb {
    border: 3px solid rgb(150, 150, 150);
    border-radius: 100vw;
    background-color: steelblue;    
}

.scrollodex::-webkit-scrollbar-thumb:hover {
    border-radius: 100vw;
    background-color: rgb(59, 111, 153);
    
}


.add-contact {
    color: whitesmoke;
    margin-top: 20px;
    background-color: steelblue;
    height: 40px;
    width: 50%;
    border-style: none;
    border-radius: 2rem;
}

.add-contact:hover {
    box-shadow: inset 0px 0px 10px 5px rgba(0, 0, 0, .5);
    font-size: medium;
}

.white-header {
    color: whitesmoke;
}

.no-link-style {
    width: 100%;
    color: whitesmoke;
    text-decoration: none;
}

@media screen and (max-width: 1500px) {
    .scrollodex {
        width: 80%;
    }
}

@media screen and (max-width: 960px) {
    .scrollodex-container {
        padding-top: 5%;
        padding-left: 5%;
        padding-right: 5%;
        margin-bottom: 20%;
    }

    .scrollodex-background {
        padding-left: 0%;
        padding-right: 0%;   
    }

    .scrollodex {
        width: 80vw;
    }

    .scrollodex::-webkit-scrollbar {
        border-top-left-radius: .5rem;
        width: 0px;
    }
}
