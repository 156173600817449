.section-container {
    text-align: center;
    padding-inline: 10%;
    padding-block: 6em;
}

.split {
    margin-block-start: 2rem;
    display: flex;
    flex-direction: column;
}

.split > * + * {
    margin-top: 2em;
}

.photo-container {
    display: flex;
    flex-direction: column;
    margin-block: 7em;
    margin-block-end: -1rem;
}

.photo-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
}

.photo-row > * + * {
    margin-left: 15px;
}

.logo-photo {
    max-height: 150px;
}

.logo-photo-rounded {
    margin-top: 15px;
    height: 110px;
    border-radius: 20px;
}

.dark-bg {
    background-color: #242424;
    color: white;
}

.section-header {
    font-family: 'Rock Salt', cursive;
}

.top-space{
    margin-top: 1em;
}

.list-title {
    font-family: 'Rock Salt', cursive;
    margin-block: auto;
}

.skills-list {
    text-align: left;
}

@media screen and (min-width: 90em) {
    .section-container {
        padding-block: 10em;
        padding-block-start: 8em;
        padding-inline: 25%;
    }

    .split {
        margin-block-start: 4rem;
        flex-direction: row;
    }
    
    .split > * {
        flex-basis: 100%;
    }

    .split > * + * {
        margin-left: 3em;
        margin-top: 0;
    }

    .top-space{
        margin-top: 3em;
        font-size: x-large;
    }
}

@media screen and (max-width: 50em) {
    .logo-photo {
        max-height: 75px;
    }

    .logo-photo-rounded {
        margin-top: 15px;
        height: 55px;
        border-radius: 10px;
    }

    .top-space{
        margin-top: 2em;
    }
}