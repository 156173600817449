.contact-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: .5rem;
    background-color: black;
    padding: 1px;
    box-shadow: -3px 5px 3px 3px rgba(0, 0, 0, .5);
}

.next-card {
    margin-top: 1.25%;
}

.card-header {
    /* background-color: rgb(124, 76, 169); */
    background-color: rgb(28, 85, 131);
    width: 100%;
    height: 60px;
    text-align: left;
    padding-left: 5%;
    padding-top: 1rem;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem; 
}

.section-line {
    width: 100%;
    height: 1px;
    background-color: black;
}

.info-container {
    width: 100%;
    display: flex;
}

.info-section {
    width: 100%;
    text-align: center;
    padding-top: 1rem;
    font-size: 100%;
    margin-left: -15%;
    padding-right: 10%;
    padding-bottom: 3rem; 
    background-color: rgb(147, 147, 147);
    border-bottom-right-radius: .5rem; 
}

.info-group {
    margin-top: 3%;
    text-align: center;
    justify-content: center;
}

.info-data {
    background-color: blanchedalmond;
    text-align: center;
    width: fit-content;
    margin-inline: auto;
    margin-top: .5rem;
    padding-inline: .5rem;
    padding-block: .25rem;
    border-radius: .25rem;
    border: 1px solid black;
}

.empty-space {
    width: 80%;
    height: auto;
    border-bottom-left-radius: .5rem;
    background-color: rgb(147, 147, 147);
    padding-top: 5%;
    padding-left: 0;
    padding-bottom: 5%;
}

.contact-picture {
    height: 150px;
    width: 150px;
    margin-left: -15%;
    border-radius: 50%;
    padding: 20%;
    background: url("../../../../public/images/blank-profile.png") center center/cover no-repeat;
    border-style: none;
}

.edit-button {
    margin-top: 3%;
    margin-right: 3%;
    background-color: steelblue;
    color: white;
    width: 30%;
    height: 15%;
    border-radius: 100vw;
    border-style: none;
}

.edit-button:hover {
    box-shadow: inset 0px 0px 10px 2.5px rgba(0, 0, 0, .5);
    font-size: 15px;
}

.delete-button {
    margin-top: 5%;
    margin-right: 3%;
    background-color: rgb(195, 46, 46);
    color: white;
    width: 30%;
    height: 15%;
    border-radius: 100vw;
    border-style: none;
}

.delete-button:hover {
    box-shadow: inset 0px 0px 10px 2.5px rgba(0, 0, 0, .5);
    font-size: 15px;
}

@media screen and (max-width: 1500px) {
    .info-section {
        font-size: 100%;
        margin-left: -50%;
        padding-left: 10%;
        width: 145%;
        border-bottom-left-radius: .5rem;
    }

    .empty-space {
        height: auto;
    }
}

@media screen and (max-width: 960px) {
    .info-section {
        width: 146%;
        margin-left: -54%;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 50px;
        font-size: 2vw;
        border-bottom-left-radius: .5rem;
    }

    .empty-space {
        height: auto;
    }

    .edit-button {
        margin-top: 5%;
        margin-bottom: 5%;
        width: 30%;
    }

    .delete-button {
        margin-top: 6%;
        margin-left: 2%;
        width: 30%;
    }

    .card-header {
        padding-top: 1rem;
    }
}

@media screen and (max-width: 536px) {
    .info-section {
        font-size: 3vw;
    }
}

@media screen and (max-width: 390px) {
    .edit-button {
        margin-left: 1%;
    }
    
    .delete-button {
        margin-left: 1%;
    }

    .info-section {
        width: 288px;
        margin-left: -75%;
        padding-top: 40px;
        font-size: small;
        border-bottom-left-radius: .5rem;
    }

    .card-header {
        padding-top: 4%;
    }
}